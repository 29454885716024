<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-2"
        id="kt_wizard_promotion_code"
        ref="kt_wizard_promotion_code"
        data-wizard-state="step-first"
        data-wizard-clickable="false"
      >
        <!--begin: Wizard Nav -->
        <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
          <div class="wizard-steps">
            <div class="wizard-step my-1" data-wizard-type="step" :data-wizard-state="step.state" v-for="step in steps" :key="step.title">
              <div class="wizard-wrapper">
                <div class="wizard-label">
                  <h3 class="wizard-title mb-0">
                    {{ step.title }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body -->
        <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
          <!--begin: Wizard Form-->
          <div class="row">
            <div class="offset-xxl-2 col-xxl-8">
              <form class="form" id="kt_form">
                
                <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                  <h1 class="mb-5 font-weight-bolder text-dark display-4">
                    Promosyon kimler için?
                  </h1>
                  <p class="font-size-h5 mb-7">
                    Promosyon kodunu kullanabilecek müşteri tipini seçin.
                  </p>

                  <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="!validation.step1">
                    <div class="alert-text text-body d-flex align-items-center">
                      <span class="svg-icon svg-icon-warning pr-2">
                        <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
                      </span>
                      <span class="font-weight-bold">Devam edebilmek için lütfen bir müşteri tipi seçin.</span>
                    </div>
                  </div>

                  <div class="form-group" v-for="type in customerTypes" :key="type.text">
                    <label
                      class="option selectable"
                      :class="{ selected: type.value == selectedCustomerType }"
                    >
                      <span class="option-control">
                        <span class="radio radio-dark">
                          <input
                            type="radio"
                            name="service_type_option"
                            :value="type.value"
                            v-model="selectedCustomerType"
                          />
                          <span></span>
                        </span>
                      </span>
                      <span class="option-label">
                        <span class="option-head">
                          <span class="option-title">
                            {{ type.name }}
                          </span>
                        </span>
                      </span>
                    </label>
                  </div>
                </div>

                <div class="pb-5" data-wizard-type="step-content">
                  <h1 class="mb-5 font-weight-bolder text-dark display-4">
                    Promosyon kodu
                  </h1>
                  <p class="font-size-h5 mb-7">
                    Oluşturmak istediğiniz promosyonu ifade eden anlamlı bir kod oluşturabilir veya rastgele bir kod da üretebilirsiniz.
                  </p>

                  <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="!validation.step2">
                    <div class="alert-text text-body d-flex align-items-center">
                      <span class="svg-icon svg-icon-warning pr-2">
                        <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
                      </span>
                      <span class="font-weight-bold"> Devam edebilmek için lütfen bir kod girin.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <input type="text" class="form-control form-control-lg text-uppercase font-weight-bold" placeholder="promosyon kodu" v-model="code.text" style="letter-spacing: 0.2em" @input="handleCodeInputChange" @change="handleCodeInputChange"/>
                    <span class="text-primary font-weight-bold d-block pt-3 pl-4" v-if="!code.isAvailable">{{ code.errorMessage }}</span>
                    <button @click="generateCode" type="button" class="btn btn-hover-transparent-dark font-weight-bold d-inline-flex align-items-center mt-3">
                      <span>Rastgele kod üret</span>
                    </button>
                  </div>
                </div>

                <div class="pb-5" data-wizard-type="step-content">
                  <h1 class="mb-5 font-weight-bolder text-dark display-4">
                    Kaç adet?
                  </h1>
                  <p class="font-size-h5 mb-7">
                    Bu promosyon kodundan kaç kopya oluşturmak istiyorsunuz?
                  </p>

                  <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="!validation.step3">
                    <div class="alert-text text-body d-flex align-items-center">
                      <span class="svg-icon svg-icon-warning pr-2">
                        <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
                      </span>
                      <span class="font-weight-bold">Devam edebilmek için lütfen bilgileri doğru ve eksiksiz girin.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <input type="number" class="form-control form-control-lg" v-model="numberOfCopy"/>
                  </div>
                </div>

                <div class="pb-5" data-wizard-type="step-content">
                  <h1 class="mb-5 font-weight-bolder text-dark display-4">
                    Geçerlilik Süresi
                  </h1>
                  <p class="font-size-h5 mb-7">
                    Promosyon kodu hangi tarihler arasında geçerlidir?
                  </p>

                  <div class="form-group">
                    <DateRangePicker format="D MMMM YYYY" input-class="form-control-lg" :initial-date="{ start: today, end: today }" :min-date="today" @change="datePickerChanged" />
                  </div>

                </div>

                <div class="pb-5" data-wizard-type="step-content">
                  <h1 class="mb-5 font-weight-bolder text-dark display-4">
                    Minimum alışveriş tutarı
                  </h1>
                  <p class="font-size-h5 mb-7">
                    En az ne kadarlık alışverişte geçerlidir?
                  </p>

                  <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="!validation.step5">
                    <div class="alert-text text-body d-flex align-items-center">
                      <span class="svg-icon svg-icon-warning pr-2">
                        <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
                      </span>
                      <span class="font-weight-bold">Devam edebilmek için lütfen bilgileri doğru ve eksiksiz girin.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend"><span class="input-group-text">TRY</span></div>
                      <input type="number" class="form-control form-control-lg" v-model="minShoppingPrice">
                      <div class="input-group-append"><span class="input-group-text">.00</span></div>
                    </div>
                  </div>
                </div>

                <div class="pb-5" data-wizard-type="step-content">
                  <h1 class="mb-5 font-weight-bolder text-dark display-4">
                    Promosyon seçenekleri
                  </h1>
                  <p class="font-size-h5 mb-7">
                    Uygulanacak indirim koşullarını belirtin.
                  </p>

                  <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="!validation.step6">
                    <div class="alert-text text-body d-flex align-items-center">
                      <span class="svg-icon svg-icon-warning pr-2">
                        <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
                      </span>
                      <span class="font-weight-bold">Devam edebilmek için lütfen bilgileri doğru ve eksiksiz girin.</span>
                    </div>
                  </div>

                  <div class="form-group" v-for="type in promotionTypes" :key="type.title">
                    <label
                      class="option selectable"
                      :class="{ selected: type.value == selectedPromotionType }"
                    >
                      <span class="option-control">
                        <span class="radio radio-dark">
                          <input
                            type="radio"
                            name="service_type_option"
                            :value="type.value"
                            v-model="selectedPromotionType"
                          />
                          <span></span>
                        </span>
                      </span>
                      <span class="option-label">
                        <span class="option-head">
                          <span class="option-title">
                            {{ type.title }}
                          </span>
                        </span>
                      </span>
                    </label>
                  </div>
                  
                  <div class="mb-3">
                    <button @click="$bvModal.show('modal-product-search')" v-if="selectedPromotionType === 2" type="button" class="btn btn-hover-transparent-dark font-weight-bold d-inline-flex align-items-center">
                      <span>Bir ürün ekle</span>
                    </button>
                  </div>

                  <div class="btn btn-icon btn-light w-auto btn-clean bg-hover-light border-0 d-inline-flex align-items-center btn-lg px-2 mr-3 mb-3" v-for="(item, index) in selectedProducts" :key="index">
                    <button type="button" class="btn btn-icon btn-circle btn-xs btn-white bg-hover-secondary mr-3" @click="removeSelectedProduct(index)">
                      <i class="ki ki-close icon-sm text-dark"></i>
                    </button>
                    <div class="d-flex flex-column align-items-start justify-content-center mr-2">
                      <span class="text-dark-50 font-weight-bolder font-size-sm line-height-nm">{{ item.text }}</span>
                    </div>
                  </div>
                  
                  <b-modal id="modal-product-search" ref="modal-product-search" no-fade centered header-class="py-3" footer-class="py-3" @close="handleModalClose" @hide="handleModalClose" @ok="handleModalOk">
                    <template #modal-header="{ cancel }">
                      <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
                      <div class="d-flex flex-fill align-items-center justify-content-center pt-2">
                        <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
                          <h5>Ürün ekleyin</h5>
                        </div>
                      </div>
                    </template>
                    
                    <div class="form-group" id="app" data-app>
                      <v-autocomplete
                        v-model="productSearchModal.model"
                        :items="items"
                        :loading="productSearchModal.isLoading"
                        :search-input.sync="productSearchModal.search"
                        item-text="Description"
                        item-value="id"
                        solo
                        clearable
                        placeholder="Ara..."
                        return-object
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>
                              Bir deneyim adı girin
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                        <template v-slot:item="data">
                          <v-list-item-content class="font-weight-bold text-body" v-text="data.item.text"></v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </div>

                    <template #modal-footer="{ ok, cancel }">
                      <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
                        <button 
                          type="button" 
                          class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
                          @click="cancel"
                        >İptal et</button>

                        <button 
                          type="button" 
                          class="btn btn-dark font-weight-bolder px-5 py-4" 
                          :disabled="productSearchModal.model === null"
                          @click="ok"
                        >
                          Kaydet
                        </button>
                      </div>
                    </template>

                  </b-modal>
                  

                </div>

                <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                  <h1 class="mb-5 font-weight-bolder text-dark display-4">
                    Geçerli para birimi?
                  </h1>
                  <p class="font-size-h5 mb-7">
                    Promosyon kodununun kullanılabileceği para birimini seçin.
                  </p>

                  <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="!validation.step7">
                    <div class="alert-text text-body d-flex align-items-center">
                      <span class="svg-icon svg-icon-warning pr-2">
                        <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
                      </span>
                      <span class="font-weight-bold">Devam edebilmek için lütfen bir para birimi seçin.</span>
                    </div>
                  </div>

                  <div class="form-group" v-for="type in currencies" :key="type.name">
                    <label
                      class="option selectable"
                      :class="{ selected: type.value == selectedCurrency }"
                    >
                      <span class="option-control">
                        <span class="radio radio-dark">
                          <input
                            type="radio"
                            name="service_type_option"
                            :value="type.value"
                            v-model="selectedCurrency"
                          />
                          <span></span>
                        </span>
                      </span>
                      <span class="option-label">
                        <span class="option-head">
                          <span class="option-title">
                            {{ type.name }}
                          </span>
                        </span>
                      </span>
                    </label>
                  </div>
                </div>

                <div class="pb-5" data-wizard-type="step-content">
                  <h1 class="mb-5 font-weight-bolder text-dark display-4">
                    İndirim tutarı
                  </h1>
                  <p class="font-size-h5 mb-7">
                    Promosyon kodu ne kadarlık bir indirim sunmakta?
                  </p>

                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <b-dropdown no-flip :text="selectedDiscountUnit.name" class="btn btn-sm btn-secondary p-0">
                          <b-dropdown-item @click="selectedDiscountUnit = item" v-for="(item, index) in discountUnits" :key="index">{{ item.name }}</b-dropdown-item>
                        </b-dropdown>
                      </div>
                      <input type="number" class="form-control form-control-lg" v-model="discountAmount">
                    </div>
                  </div>
                </div>
                

                 <!--begin: Wizard Actions -->
                <div class="d-flex justify-content-between border-top pt-10">
                  <div class="mr-2">
                    <button
                      class="btn btn-secondary font-weight-bold px-9 py-4"
                      data-wizard-type="action-prev"
                    >
                      Geri
                    </button>
                  </div>
                  <div>
                    <button
                      @click="submit"
                      class="btn btn-dark font-weight-bolder px-9 py-4"
                      :class="{ 'spinner spinner-white spinner-left mr-3': isSubmitting }"
                      ref="kt_create_category"
                      data-wizard-type="action-submit"
                      :disabled="isSubmitting"
                    >
                      Promosyon Kodu Oluştur
                    </button>
                    <button
                      class="btn btn-dark font-weight-bolder px-9 py-4"
                      data-wizard-type="action-next"
                    >
                      Sonraki Adım
                    </button>
                  </div>
                </div>
                <!--end: Wizard Actions -->

              </form>
            </div>
            <!--end: Wizard-->
          </div>
        </div>
        <!--end: Wizard Body -->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from 'moment';
import KTWizard from "@/assets/js/components/wizard";
import DateRangePicker from "@/view/content/datepicker/DateRangePicker";
import ApiService from '@/core/services/api.service';

export default {
  components: {
    DateRangePicker,
  },
  data() {
    return {
      wizard: null,
      steps: [
        {
          title: 'Müşteri Tipi',
          state: 'current'
        },
        {
          title: 'Promosyon Kodu',
          state: 'pending'
        },
        {
          title: 'Kopya Sayısı',
          state: 'pending'
        },
        {
          title: 'Geçerlilik Süresi',
          state: 'pending'
        },
        {
          title: 'Minimum Alışveriş Tutarı',
          state: 'pending'
        },
        {
          title: 'Promosyon Tipi',
          state: 'pending'
        },
        {
          title: 'Geçerli Para Birimi',
          state: 'pending'
        },
        {
          title: 'İndirim Tutarı',
          state: 'pending'
        },
      ],
      
      // STEP 1
      selectedCustomerType: null,
      
      // STEP 2
      code: {
        text: '',
        isChecking: false,
        timeout: null,
        errorMessage: '',
        isAvailable: true
      },

      // STEP 3
      numberOfCopy: 1,

      // STEP 4
      datePicker: {
        start: moment().format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
      },

      // STEP 5
      minShoppingPrice: 0,

      // STEP 6
      selectedPromotionType: null,
      promotionTypes: [
        {
          title: 'Tüm Ürünlerde Geçerli',
          value: 1,
        },
        {
          title: 'Seçili Ürünlerde Geçerli',
          value: 2,
        }
      ],
      productSearchModal: {
        entries: [],
        isLoading: false,
        model: null,
        search: null,
      },
      selectedProducts: [],

      // STEP 7
      selectedCurrency: null,
      
      // STEP 8
      selectedDiscountUnit: {
        name: 'Yüzde',
        value: 'PCT'
      },
      discountAmount: 0,

      validation: {
        step1: true,
        step2: true,
        step3: true,
        step5: true,
        step6: true,
        step7: true,
      },
      isSubmitting: false
    }
  },
  computed: {
    ...mapGetters(["promotionConfig"]),

    today() {
      return moment().format('YYYY-MM-DD');
    },
    fields () {
      if (!this.productSearchModal.model) return []

      return Object.keys(this.productSearchModal.model).map(key => {
        return {
          key,
          value: this.productSearchModal.model[key] || 'n/a',
        }
      })
    },
    items () {
      return this.productSearchModal.entries.map(entry => {
        const Description = entry.text;

        return Object.assign({}, entry, { Description })
      })
    },
    selectedProductsArr() {
      let arr = [];
      this.selectedProducts.forEach(element => {
        arr.push(element.id)
      });
      return arr;
    },
    customerTypes() {
      let arr = [];
      Object.entries(this.promotionConfig(`customerType`)).forEach(([,obj]) => {
        arr.push({
          value: obj.value,
          name: obj.text
        });
      });
      return arr;
    },

    discountUnits() {
      let arr = [];
      Object.entries(this.promotionConfig(`discountUnit`)).forEach(([,obj]) => {
        arr.push({
          value: obj.value,
          name: obj.text
        });
      });
      return arr;
    },

    currencies() {
      let arr = [];
      Object.entries(this.promotionConfig(`currency`)).forEach(([,obj]) => {
        arr.push({
          value: obj.value,
          name: obj.text
        });
      });
      return arr;
    },
  },
  watch: {
    'productSearchModal.search': function(val) {
      // Items have already been loaded
      if (this.items.length > 0) return

      // Items have already been requested
      if (this.productSearchModal.isLoading) return

      this.productSearchModal.isLoading = true

      // Lazily load input items
      ApiService.get(`experience/search.req.php?search=${val}`)
      .then(({ data }) => {
        this.productSearchModal.entries = data.listings;
        this.productSearchModal.isLoading = false;
      })
      .catch(() => {
        this.productSearchModal.entries = [];
        this.productSearchModal.isLoading = false;
      });
    },
  },
  mounted() {
    moment.locale('tr');
    const self = this;
    // Initialize form wizard
    this.wizard = new KTWizard("kt_wizard_promotion_code", {
      startStep: 1, // initial active step number
      clickableSteps: false // allow step clicking
    });

    // Change event
    this.wizard.on("change", function(wizardObj) {
      if (wizardObj.newStep < wizardObj.currentStep) {
        return;
      }

      const step = wizardObj.getStep();
      switch (step) {
        case 1:
          if (self.selectedCustomerType === null) {
            self.validation.step1 = false;
            wizardObj.stop();
          } else { self.validation.step1 = true; }
          break;
        case 2:
          if (self.code.text.length === 0) {
            self.validation.step2 = false;
            wizardObj.stop();
          } else { 
            if (!self.code.isAvailable) {
              wizardObj.stop();
            } else {
              self.validation.step2 = true; 
            }
          }
          break;
        case 3:
          if (self.numberOfCopy.length === 0 || self.numberOfCopy <= 0) {
            self.validation.step3 = false;
            wizardObj.stop();
          } else { self.validation.step3 = true; }
          break;
        case 5:
          if (self.minShoppingPrice.length === 0 || self.minShoppingPrice < 0) {
            self.validation.step5 = false;
            wizardObj.stop();
          } else { self.validation.step5 = true; }
          break;
        case 6:
          if (self.selectedPromotionType === null) {
            self.validation.step6 = false;
            wizardObj.stop();
          } else { 
            if (self.selectedPromotionType === 2 && self.selectedProducts.length === 0) {
              self.validation.step6 = false;
              wizardObj.stop();
            } else {
              self.validation.step6 = true; 
            }
          }
          break;
        case 7:
          if (self.selectedCurrency === null) {
            self.validation.step7 = false;
            wizardObj.stop();
          } else { self.validation.step7 = true; }
          break;

        default:
          break;
      }
    });
  },
  methods: {
    generateCode() {
      this.code.text = Math.random().toString(36).substr(2, 8);
    },
    handleModalOk() {
      this.selectedProducts.push(this.productSearchModal.model);
    },
    handleModalClose() {
      this.productSearchModal.model = null;
    },
    removeSelectedProduct(index) {
      this.selectedProducts.splice(index, 1);
    },
    submit() {
      this.isSubmitting = true;
      const postData = {
        code: this.code.text.toUpperCase(),
        customerType: this.selectedCustomerType,
        usesPerCoupon: this.numberOfCopy,
        validFrom: this.datePicker.start,
        validTo: this.datePicker.end,
        minBasketPrice: this.minShoppingPrice,
        productType: this.selectedPromotionType,
        validCurrency: this.selectedCurrency,
        discountUnit: this.selectedDiscountUnit.value,
        discountAmount: this.discountAmount,
        selectedProducts: this.selectedProductsArr
      }
      
      ApiService.post('promotion-code/add.req.php', postData)
      .then(() => {
        this.isSubmitting = false;
        this.$router.push({ name: "promotion-code-details", params: { code: postData.code } })
      })
      .catch(({ response }) => {
        this.isSubmitting = false;
        alert(response.data.errorMessage);
      });

    },

    datePickerChanged(dateRange) {
      this.datePicker.start = dateRange.start;
      this.datePicker.end = dateRange.end;
    },

    handleCodeInputChange() {
      const self = this;
      clearTimeout(this.code.timeout);
      this.code.timeout = setTimeout(function () {
        self.checkCode();
      }, 1000);
    },

    checkCode() {
      ApiService.get(`promotion-code/check.req.php?code=${this.code.text}`)
      .then(() => {
        this.code.isAvailable = true;
      })
      .catch(({ response }) => {
        this.code.errorMessage = response.data.errorMessage;
        this.code.isAvailable = false;
      });
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-2.scss";

.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step {
  border: 2px solid transparent;
  border-radius: 0.85rem;
}
.wizard.wizard-2
  .wizard-nav
  .wizard-steps
  .wizard-step[data-wizard-state='current']:after {
  display: none;
}
.option {
  cursor: pointer;
  border-width: 2px;
}
.option .option-label .option-body {
  font-size: 1.08rem !important;
  max-width: 80% !important;
}
.option.selectable.selected,
.option.selectable:hover {
  border-color: var(--dark);
  background-color: var(--light);
}
.option.selected {
  border-width: 2px;
}
</style>